<template>
  <v-footer id="dashboard-core-footer"   
  fixed       
    absolute
    height="30"
    padless
    >
    
    <v-container>
      <v-row align="center">
        <v-col
          v-for="(link, i) in links"
          :key="i"
          class="text-center"
          cols="auto"
        >
          <v-btn
            text
            color="error"
            :href="link.href"
            class="grey--text text--darken-3"
            rel="noopener"
            target="_blank"
            v-text="link.text" 
          >     
          </v-btn>
        </v-col>

        <v-spacer class="hidden-sm-and-down" />

        <v-col cols="12" md="auto">
          <div class="font-weight-bold text-center">
            &copy; 2021 กลุ่มงานจัดการบุคคล 2, made with
            <v-icon size="18" color="red">
              mdi-heart
            </v-icon>
            by <a href="https://ovec.go.th/">Team DIGITAL VEC</a>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: "DashboardCoreFooter",

  data: () => ({
    links: [
      {
        href: "https://ipa.vec.go.th/",
        text: "กลุ่มงานจัดการบุคคล 2",
        icon: 'mdi-account-multiple',
      },
      {
        href: "https://boga.vec.go.th/",
        text: "สำนักอำนวยการ"
      }
    ]
  })
};
</script>

<style lang="sass">
#dashboard-core-footer
  a
    font-size: .825rem
    font-weight: 500
    text-decoration: none
    text-transform: uppercase
</style>
